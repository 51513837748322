import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/nicholaswarner/dawnstar/MM-W/catalyst/node_modules/gatsby-theme-catalyst-core/src/components/layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Seo = makeShortcode("Seo");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Seo title="Home" mdxType="Seo" />
    <h1 {...{
      "id": "mudmuseum"
    }}>{`MudMuseum`}</h1>
    <p>{`A living archive of mud codebases built over the last half century. Multi-User Dungeons, started as a gaming genre
in 1978, have a rich history in gaming, computer science, and `}</p>
    <h2 {...{
      "id": "mud-basics"
    }}>{`MUD Basics`}</h2>
    <p>{`MUDs are generally accessible through a telnet connection to a host using a custom port. A variety of MUD clients that
help players connect to MUDs have been launched over the years. For a full getting started tutorial, please see the
`}<a parentName="p" {...{
        "href": "https://docs.mudmuseum.com",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`MudMuseum Getting Started Docs`}</a>{`. If you’d like to jump right in and have a telnet client
available to you, you can check out `}<a parentName="p" {...{
        "href": "#Connect"
      }}>{`Connect`}</a>{`.`}</p>
    <p>{`For a list of available MUD clients, please see the `}<a parentName="p" {...{
        "href": "#FIXLINK"
      }}>{`Mud Client Listing`}</a></p>
    <h2 {...{
      "id": "connect"
    }}>{`Connect`}</h2>
    <p>{`The Mud Museum hosts a wide variety of archived muds made available at mud.mudmuseum.com. A full listing can be seen
at `}<a parentName="p" {...{
        "href": "#FIXLINK"
      }}>{`MudMuseum MUD Listing`}</a>{`. You can quickly access a few of the original codebases with the following:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`telnet mud.mudmuseum.com ####
`}</code></pre>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Codebase`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Version`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Address & Port`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Rom`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2.4.b4`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`mud.mudmuseum.com 8000`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Dystopia`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Gold (~1.2)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`mud.mudmuseum.com 9000`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "fixlink"
    }}>{`FIXLINK`}</h2>
    <p>{`Need to fix links for `}<em parentName="p">{`Mud Client Listing`}</em>{` and `}<em parentName="p">{`MudMuseum MUD Listing`}</em>{` on the index page.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      